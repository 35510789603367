import { render, staticRenderFns } from "./PasswordRecovery.vue?vue&type=template&id=70567e60&scoped=true"
import script from "./PasswordRecovery.vue?vue&type=script&lang=js"
export * from "./PasswordRecovery.vue?vue&type=script&lang=js"
import style0 from "./PasswordRecovery.vue?vue&type=style&index=0&id=70567e60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70567e60",
  null
  
)

export default component.exports