<template>
  <div>
    <!-- Password Recovery  -->
    <b-container class="thisContainer">
      <div
        class="bg-white passwordResetStyle animate__animated animate__fadeIn shadow"
      >
        <b-form @submit="handlePasswordRecovery">
          <h3 class="title">Password Recovery</h3>
          <div class="inputWrapper">
            <b-input
              v-model="resetNumber"
              type="text"
              class="my-4 p-4 pl-5"
              placeholder="Phone Number"
              required
            ></b-input>
            <i class="fas fa-phone"></i>
          </div>
          <div class="inputWrapper">
            <b-input
              v-model="resetEmail"
              type="email"
              class="my-4 p-4 pl-5"
              placeholder="Email Address"
              required
            ></b-input>
            <i class="fas fa-envelope"></i>
          </div>
          <div class="text-center my-3">
            <button class="mybtn" style="font-size: 18px">Submit</button>
          </div>
          <div class="text-center back">
            <router-link to="/">
              <h6>&lt; Back</h6>
            </router-link>
          </div>
        </b-form>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PasswordRecovery",
  data() {
    return {
      resetNumber: "",
      resetEmail: "",
    };
  },
  methods: {
    openMessageBox() {
      alert("Please log in with your temporary password.");
      this.$alert("Please check your email for temporary password.", "Alert", {
        confirmButtonText: "Confirm",
        callback: () => {
          this.$message({
            type: "info",
            message: `Please log in with your temporary password.`,
          });
        },
      });
    },
    handlePasswordRecovery(event) {
      event.preventDefault();
      let url =
        "?pnum=" +
        this.resetNumber +
        "&email=" +
        this.resetEmail.replace("@", "%40");
      this.$axios
        .post("/LoginRegistration/RequestTempPassword" + url, {
          pnum: this.resetNumber,
          email: this.resetEmail,
        })
        .then((response) => {
          if (response.data[0] === "a") {
            this.$notify.error({
              title: "Error !",
              message: "Account Not Found !",
            });
          } else {
            this.$router.push("/login");
            setTimeout(() => {
              this.$notify({
                title: "Success",
                message: "Please check your email/SMS for temporary password.",
                type: "success",
              });
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify.error({
            title: "Error !",
            message: "Network Error !",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.thisContainer {
  padding-top: 4%;
  width: 850px;
}

.title {
  font-size: 28px;
  color: #0394ca;
}

.inputWrapper {
  position: relative;
  font-size: 18px !important;
  i {
    color: #d2d2d2;
    position: absolute;
    font-size: 18px;
    top: 16px;
    left: 16px;
  }
}

.passwordResetStyle {
  width: 60%;
  margin: auto;
  padding: 60px 100px;
  border-radius: 10px;
}

.back {
  h6 {
    font-weight: 500;
    color: #0394ca;
    font-size: 18px;
  }
  h6:hover {
    cursor: pointer;
    color: #131313;
    transition: 0.4s;
  }
}

::placeholder {
  opacity: 0.3; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.3;
}

@media (max-width: 768px) {
  .thisContainer {
    padding-top: 0px;
    width: 100%;
    margin-top: -10px;
  }

  .passwordResetStyle {
    width: 100%;
    padding: 60px;
    margin-top: 10%;
  }
}
</style>
